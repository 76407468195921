import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm, change, formValueSelector} from "redux-form";
import {useTranslation} from "react-i18next";
import {Grid, CardHeader} from "@mui/material";
import {FaUserEdit} from "react-icons/fa";
import {
  Avatar,
  Autocomplete,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "react-phone-input-2/lib/bootstrap.css";
import {
  onSelectPatientObject,
} from "../../../store/features/newRequestSlice";
import {
  fetchPatientFreeTextSearch,
} from "../../../store/middleware/masterdataThunk";

import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextArea} from "components/formFields/TextArea";
import {InsuranceNo} from "components/formFields/InsuranceNo";
import {Calendar} from "components/formFields/Calendar";
import {DropdownGender} from "components/formFields/DropdownGender";
import {PhoneComponentPatient} from "components/formFields/PhoneComponentPatient";
import {CountryDropdown} from "components/formFields/CountryDropdown";
import {CheckboxComponent} from "components/formFields/CheckboxComponent";
import {TextFieldComponent} from "components/formFields/TextFieldComponent";

import {
  fieldNames,
  fieldLevelValidator,
  formInitialValues,
  normalizePatientNo,
  datepickerLimitations,
  correctDateFormat,
} from "components/formUtils";
import {useState} from "react";


const {validDate,
  required,
  requiredCheckbox,
  lengthLessThanTwo,
  validEmail,
  validInsuranceNo,
  validPhoneNo,
  validPostalCode,
  validGender} = fieldLevelValidator;
const ReduxFormComponentPatient = (props) => {
  const {t, i18n} = useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const [patientInsurance, setPatientInsurace]=useState({DESCR1: "", GLN: ""});
  const dispatch = useDispatch();
  const {
    patientData,
    needsValidation,
    isEdit,
    patientDataHistory,
    patientSearchResults,
    isLoadingPatientsearch,
    insuranceSearchResults,
    changeValue,
    formValues,
  } = props;
  useEffect(() => {
    fieldNames.patient.forEach((e)=>changeValue(e, patientData?.[e]??formInitialValues.patient[e]));
  }, [patientData]);
  useEffect(()=>{
    if (patientData?.patient_insurance_company) {
      changeValue("patient_insurance_company_gln", patientData.patient_insurance_company_gln);
      changeValue("patient_insurance_company", patientData.patient_insurance_company);
      const patientInsuranceOption={DESCR1: patientData.patient_insurance_company,
        GLN: patientData.patient_insurance_company_gln};
      setPatientInsurace(patientInsuranceOption);
    } else {
      setPatientInsurace({DESCR1: "", GLN: ""});
    }
  }, [props.patientData]);

  useEffect(()=>{
    if (document.getElementsByName("patient_surename")) document.getElementsByName("patient_surename")[0]?.setAttribute("autocomplete", "off");
    if (document.getElementsByName("patient_lastname")) document.getElementsByName("patient_lastname")[0]?.setAttribute("autocomplete", "off");
    if (document.getElementsByName("patient_street")) document.getElementsByName("patient_street")[0]?.setAttribute("autocomplete", "new-password");
    if (document.getElementsByName("patient_postalcode")) document.getElementsByName("patient_postalcode")[0]?.setAttribute("autocomplete", "new-password");
    if (document.getElementsByName("patient_city")) document.getElementsByName("patient_city")[0]?.setAttribute("autocomplete", "new-password");
    if (document.getElementsByName("patient_email_addr")) document.getElementsByName("patient_email_addr")[0]?.setAttribute("autocomplete", "off");
  }, []);

  const delay = (()=> {
    let timeout = null;
    return (callback, ms)=> {
      clearTimeout(timeout);
      timeout=setTimeout(callback, ms);
    };
  })();
  return (
    <div className="">
      <CardHeader
        className="p-0 pb-3"
        avatar={
          <Avatar aria-label="recipe" style={{
            color: "#63c2de",
            backgroundColor: "#f0f3f5",
          }}>
            <FaUserEdit data-toggle="popover" data-content="Disabled popover" />
          </Avatar>
        }
        title={t("newrequest.label.patient.details")}
      />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="patient_search"
            freeSolo
            autoHighlight={true}
            defaultValue={() => {
            }}
            required={true}
            options={patientSearchResults || []}


            getOptionLabel={(option) => {
              let labelForOption = option.firstname + " " + option.name;

              if (option.insurance_no) {
                labelForOption = labelForOption + ", " +
                "AHV:" + option.insurance_no;
              }
              if (option.insurance_company) {
                labelForOption = labelForOption +
                " " + option.insurance_company;
              }

              if (option.street || option.city || option.zip ) {
                labelForOption = labelForOption + ", " +
                t("patient.label.addresse") + ": " +
                option.street + ", " +
                option.city + ", " +
                option.country + "-" + option.zip;
              }
              if (option.email_addr ) {
                labelForOption = labelForOption + ", " +
                t("patient.email") + ": " +
                option.email_addr;
              }
              return labelForOption;
            }}


            onInputChange={(event, newInputValue) => {
              if (event?.type === "change") {
                delay(() => dispatch(fetchPatientFreeTextSearch(newInputValue, "false")), 1000);
              }
            }}
            onChange={(event, value, reason, details) => {
              if (details && details.option) {
                dispatch(onSelectPatientObject(details.option));
                if (details.option.insurance_company_gln!=="") {
                  const patientInsuranceOption={
                    DESCR1: details.option.insurance_company,
                    GLN: details.option.insurance_company_gln,
                  };
                  setPatientInsurace(patientInsuranceOption);
                }
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label={t("newrequest.label.searchpatient")}
                variant="standard"
                multiline
                data-cy="patient_search"
                InputProps={{
                  ...params.InputProps,
                  readOnly: isLoadingPatientsearch,
                  startAdornment: ( <InputAdornment position="start"> <SearchIcon />
                  </InputAdornment> ),
                  endAdornment: (
                    <React.Fragment>
                      {(isLoadingPatientsearch) ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />}
          />
        </Grid>
      </Grid>
      <canvas hidden={true} id="myCanvas" width="200" height="100"></canvas>
      <Grid container spacing={3} justifyContent="center">
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Field
              name="patient_surename"
              component={AutocompleteField}
              options={patientDataHistory?.patient_surename}
              required={true}
              validate={[required, lengthLessThanTwo]}
              touched={needsValidation}
              label={t("patient.label.surname")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field name="patient_lastname"
              component={AutocompleteField}
              options={patientDataHistory?.patient_lastname}
              required={true}
              validate={[required, lengthLessThanTwo]}
              touched={needsValidation}
              label={t("patient.label.lastname")}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={4}>
            <Field name="patient_date_of_birth"
              required={true}
              validate={[validDate, required]}
              touched={needsValidation}
              normalize={correctDateFormat}
              component={Calendar}
              language={currentLang}
              maxDate={datepickerLimitations.patient.patient_date_of_birth.maxDate}
              label={t("patient.label.dob")}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Field name="patient_gender"
              required={true}
              validate={[validGender]}
              genderOptions={
                [i18n.t("patient.gender.male"),
                  i18n.t("patient.gender.female"),
                  i18n.t("patient.gender.other")]
              }
              touched={needsValidation}
              component={DropdownGender}
              normalize={(e) =>
                [i18n.t("patient.gender.male"),
                  i18n.t("patient.gender.female"),
                  i18n.t("patient.gender.other")].indexOf(e)}
              label={t("patient.label.gender")}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={4}>
            <Field
              name="patient_insurance_no"
              data-cy="patient_insurance_no"
              component={InsuranceNo}
              required={true}
              validate={[required, validInsuranceNo]}
              touched={needsValidation}
              options={patientDataHistory?.patient_insurance_no}
              normalize={normalizePatientNo}
              label={t("patient.label.insurance.no")}
              id="patient_insurance_no"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="patient_ins"
              autoSelect={true}
              autoHighlight={true}
              value={patientInsurance}
              required={true}
              isOptionEqualToValue={(option, value)=>(option.DESCR1===value.DESCR1)||
                                          (value.DESCR1===""&&value.GLN==="")}
              options={insuranceSearchResults || []}
              getOptionLabel={(option) => option.DESCR1||""}
              onChange={(event, value, reason, details) => {
                if (details && details.option && details.option.GLN) {
                  changeValue("patient_insurance_company_gln", details.option.GLN);
                  changeValue("patient_insurance_company", details.option.DESCR1);
                  setPatientInsurace(details.option);
                }
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("patient.label.insurance.company")}
                  variant="standard"
                  data-cy="patient_insurance_company"
                  autoComplete="new-password"
                  error={needsValidation&&patientInsurance.DESCR1===""?true:false}
                  helperText={needsValidation&&patientInsurance.DESCR1===""?t("field.required"):null}
                  required={true}
                  InputProps={{
                    ...params.InputProps,
                    "autoComplete": "new-password",
                  }}
                />}
            />
          </Grid>
        </Grid>
        <Field name="patient_insurance_company_gln" hidden component={TextArea}/>
        <Field name="patient_insurance_company"
          validate={[required]}
          touched={needsValidation}
          hidden component={TextArea}/>
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Field name="patient_street"
              component={AutocompleteField}
              options={patientDataHistory?.patient_street}
              required={true}
              validate={[required, lengthLessThanTwo]}
              touched={needsValidation}
              label={t("patient.label.street")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field name="patient_postalcode"
              component={AutocompleteField}
              options={patientDataHistory?.patient_postalcode}
              required={true}
              validate={[required, validPostalCode]}
              touched={needsValidation}
              label={t("patient.label.zip")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Field name="patient_city"
              component={AutocompleteField}
              options={patientDataHistory?.patient_city}
              touched={needsValidation}
              required={true}
              validate={[required, lengthLessThanTwo]}
              label={t("patient.details.city")}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Field name="patient_country"
              required={true}
              validate={[required]}
              language={currentLang}
              touched={needsValidation}
              component={CountryDropdown}
              label={t("patient.label.country")}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item className="pt-1" xs={12} sm={4}>
            <Field name="patient_phone_no"
              component={PhoneComponentPatient}
              options={patientDataHistory?.patient_phone_no}
              id="patient_phone_no"
              isEdit={isEdit}
              validate={[validPhoneNo]}
              patientCountry={formValues.patient_country}
              placeholder={t("patient.label.phone.no")}
            />
          </Grid>
          <Grid item className="pt-3" xs={12} sm={8}>
            <Field name="patient_email_addr"
              id="patient_email_addr"
              required={false}
              touched={needsValidation}
              component={TextFieldComponent}
              isEdit={isEdit}
              validate={[validEmail]}
              type="email"
              label={t("patient.label.email")}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Field name="patient_agreement"
            touched={needsValidation}
            component={CheckboxComponent}
            required={true}
            validate={[requiredCheckbox]}
            label={t("patient.agreement")}
            normalize={(v) => v ? 1 : 0}
            format={(v) => v === 1}
          />
        </Grid>
      </Grid>
    </div>
  );
};

let PatientDetailsForm = reduxForm({
  enableReinitialize: false,
  form: "patientContact",
})(ReduxFormComponentPatient);

const mapDispatchToProps = (dispatch) => ({
  changeValue: (field, value) => dispatch(change("patientContact", field, value)),
  changeFieldValue: (field, value) => dispatch(change("patientContact", field, value)),
});

const mapStateToProps = (state) => {
  return {
    patientDataHistory: state.singleRequest.patientDataHistory,
    formValues: formValueSelector("patientContact")(state,
        "patient_country",
        "patient_phone",
        "patient_insurance_company",
        "patient_insurance_company_gln"),
    needsValidation: state.singleRequest.needsValidation.patient,
    patientData: state.singleRequest.patientData,
    patientSearchResults: Array.isArray(state.masterdata?.autocompleteOptions?.patientSearch)?
    state.masterdata?.autocompleteOptions?.patientSearch:
    [],
    isLoadingPatientsearch: state.loading.patientSearch,
    insuranceSearchResults: Array.isArray(state.masterdata?.autocompleteOptions?.insuranceCompany)?
    state.masterdata?.autocompleteOptions?.insuranceCompany.filter((e, index, array) =>
      index === array.findIndex((t) => (t.DESCR1 === e.DESCR1))):
      [],
    selectedPatientFromSearch: state.singleRequest.selectedPatientFromSearch,
  };
};

PatientDetailsForm = connect(mapStateToProps, mapDispatchToProps)(PatientDetailsForm);

export default PatientDetailsForm;
