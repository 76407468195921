import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";

import {useLocation} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {calendarLanguage} from "pages/Wizard/containers/utils";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";

import {
  onChangeTalveyValue,
  selectTalveyValues,
  onAddMedication,
  onRemoveMedication,
} from "./TalveySlice";


const TalveyForm = (props) => {
  const {t, i18n}=useTranslation();
  const dispatch = UseAppDispatch();
  const TalveyValues = UseAppSelector(selectTalveyValues);
  const params = useLocation();
  const regex = /\d+$/;
  const isEdit = regex.test(params.pathname);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const currentLang=i18n.language.toUpperCase();


  useEffect(() => {
    if (!isEdit) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [
    params.pathname,
  ]);

  useEffect(() => {
    if (TalveyValues.isMonotherapy) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(onChangeMedicineValue({field: "kn_id", value: "21690.01"}));
    } else {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    }
  }, [
    TalveyValues.isMonotherapy,
  ]);


  const handleAddMedication = () => {
    dispatch(onAddMedication({name: "", start: "", stop: "", comment: ""})); // Add an empty medication item
  };

  const handleRemoveMedication = (index) => {
    dispatch(onRemoveMedication(index));
  };

  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = TalveyValues.medications.map((medication, idx) =>
      idx === index ? {...medication, [field]: value} : medication,
    );
    dispatch(onChangeTalveyValue({formData: {medications: updatedMedications}}));
  };


  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="talvey-radio-buttons-group-form-type"
              name="talvey-radio-buttons-group-form-type"
              onChange={(event, value) => {
                if (value === "isOtherIndication") {
                  dispatch(onChangeTalveyValue({formData: {isMonotherapy: false}}));
                  dispatch(onChangeTalveyValue({formData: {isOtherIndication: true}}));
                } else {
                  dispatch(onChangeTalveyValue({formData: {isMonotherapy: true}}));
                  dispatch(onChangeTalveyValue({formData: {isOtherIndication: false}}));
                }
              }}>
              <FormControlLabel
                value="isMonotherapy"
                control={<Radio checked={TalveyValues.isMonotherapy}
                  data-cy="isMonotherapy" />}
                label={t("talvey.isMonotherapy")}
              />
              <FormControlLabel
                value="isOtherIndication"
                control={<Radio checked={TalveyValues.isOtherIndication}
                  data-cy="isOtherIndication" />}
                label={t("talvey.isOtherIndication")}
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{m: 1}}/>
        </Grid >
        <Grid item xs={12} >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
            hidden={!TalveyValues.isMonotherapy}>
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("talvey.warning.limitatio")}</Typography>
          </Stack>
        </Grid>

        {
          // Medication Block Array
        }


        <Grid item xs={12} hidden={!TalveyValues.isMonotherapy}>

          <Typography>{t("talvey.drugplan.info")}</Typography>
          <Box sx={{m: 1}}/>

          {TalveyValues.medications.map((medication, index) => (
            <Grid key={index} container spacing={2} >
              <Grid xs={10} item>
                <Typography>{t("talvey.drugplan.therapy")}: {index+1}</Typography>
                <Box sx={{m: 1}} />
                <TextField
                  data-cy="medicationName"
                  label={t("talvey.medicationName")}
                  value={medication.name}
                  fullWidth
                  variant="outlined"
                  onChange={(event) => handleMedicationChange(index, "name", event.target.value)}
                />
              </Grid>
              <Grid xs={2} item display="flex" alignItems="center" justifyContent="center">
                <IconButton
                  data-cy="removeMedication"
                  onClick={() => handleRemoveMedication(index)} color="secondary">
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  inputFormat="MM/yyyy"
                  label={t("talvey.start")}
                  views={["year", "month"]}
                  value={medication.start}
                  onChange={(newValue) => handleMedicationChange(index, "start", newValue)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth data-cy="start-date" variant="outlined" InputLabelProps={{shrink: true}} />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  inputFormat="MM/yyyy"
                  label={t("talvey.stop")}
                  views={["year", "month"]}
                  value={medication.stop}
                  onChange={(newValue) => handleMedicationChange(index, "stop", newValue)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth data-cy="stop-date" variant="outlined" InputLabelProps={{shrink: true}} />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  label={t("talvey.comment")}
                  variant="outlined"
                  value={medication.comment}
                  fullWidth
                  data-cy="medication-comment"
                  onChange={(event) => handleMedicationChange(index, "comment", event.target.value)}
                />
              </Grid>
              <Box sx={{m: 1}}/>
            </Grid>

          ))}
          <Box sx={{m: 1}}/>
          <Button
            data-cy="addMedication"
            onClick={handleAddMedication}
            variant="contained" startIcon={<Add />}>
            {t("talvey.addMedication")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} hidden={!TalveyValues.isMonotherapy}>
        <TextField
          label={t("talvey.comments")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => dispatch(onChangeTalveyValue({formData: {comments: event.target.value}}))}
          value={TalveyValues.comments || ""}
          data-cy="comments"
        />

      </Grid>

      <Grid
        hidden={
          !(
            TalveyValues.isOtherIndication
          )
        }
        item xs={12}>

        <Grid item xs={12} >
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"primary"}></WarningIcon>
            <Typography>{t("talvey.warning.offlabel")}</Typography>
          </Stack>
        </Grid>
        <Box sx={{m: 3}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("preTreatment")}
          value={TalveyValues.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeTalveyValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          data-cy="patientCondition"
          style={{width: "100%"}}
          label={t("patientCondition")}
          InputLabelProps={{shrink: true}}
          value={TalveyValues.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeTalveyValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          data-cy="reasonsAlternativeTherapiesFailed"
          label={t("reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={TalveyValues.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeTalveyValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("prognosis")}
          data-cy="prognosis"
          InputLabelProps={{shrink: true}}
          value={TalveyValues.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeTalveyValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </Grid>


    </LocalizationProvider>

  </>;
};

const mapStateToProps = (state) => {
  return {
    // Map state to props here if needed
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Map dispatch to props here if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(TalveyForm);
