import React, {useEffect} from "react";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
}
  from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {
  IPatientState,
  onChangePatientValue,
  selectPatientValues,
  selectPatientValidationErrors,
  onSelectSearchedPatient,
  clearSpecificPatientState,
  onReceivePatientEditData,
} from "./patientSlice";
import {calendarLanguage, useIsInViewport, calendarMask} from "pages/Wizard/containers/utils";
import {selectInsuranceCompany, selectPatientSearchResult} from "store/features/masterdataSlice";
import {fetchInsuranceCompaniesData, fetchPatientFreeTextSearch} from "store/middleware/masterdataThunk";
import {correctDateFormat, countries, datepickerLimitations, validateEmail} from "components/formUtils";
import {ICountry, IInsuranceCompany} from "store/rootstate";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {InsuranceNoComponent} from "../../components/InsuranceNo";
import {PhoneNoComponent} from "../../components/PhoneNo";
import {EmailComponent} from "pages/Wizard/components/Email";
import {delay} from "store/api";
import {selectLoadingState} from "store/features/loadingSlice";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {selectPatientData} from "store/features/newRequestSlice";


export const PatientForm = (props) => {
  const {t, i18n} = useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const formValues = UseAppSelector(selectPatientValues);
  const validationErrors = UseAppSelector(selectPatientValidationErrors);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const insuranceCompanyList = UseAppSelector<readonly IInsuranceCompany[]>(selectInsuranceCompany);
  const dispatch = UseAppDispatch();
  const handleValueChange = (field: keyof IPatientState["formData"], value: any) => dispatch(onChangePatientValue({field, value}));
  const isVisible = useIsInViewport(props.refProps);
  const loading=UseAppSelector(selectLoadingState);
  const patientSearchResults=UseAppSelector(selectPatientSearchResult);
  const patientDataFromOcr=UseAppSelector(selectPatientData);

  useEffect(()=>{
    isVisibleValues.patient!==isVisible&&dispatch(onViewChange({field: "patient", value: isVisible}));
  }, [isVisible]);

  useEffect(() => {
    dispatch(fetchInsuranceCompaniesData());
  }, []);

  useEffect(()=>{
    if (patientDataFromOcr) {
      dispatch(onReceivePatientEditData(patientDataFromOcr));
    };
  }, [patientDataFromOcr]);

  useEffect(() => {
    // used on edit data because we don't store the insurance email in the DB
    if (
      Boolean(formValues.patient_insurance?.GLN)&& // the patient has an insurance GLN
    !Boolean(formValues.patient_insurance?.email)&& // the patient doesn't have insurance email
    insuranceCompanyList?.length>0 // insurance list was loaded
    ) {
      // add insurance email to the request
      handleValueChange("patient_insurance", insuranceCompanyList?.find((e)=>e.GLN===formValues.patient_insurance.GLN));
    }
  }, [formValues.patient_insurance?.GLN, insuranceCompanyList]);
  return (<Grid ref={props.refProps} container direction="column" spacing={3} marginTop='3px' id="patient">
    <Grid item container direction="column" spacing={2}>
      <Grid item style={{"borderLeft": "7px solid #63c2de", "paddingTop": 0}}>
        <Typography variant="h5" >
          {t(props.label)}
        </Typography>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Stack
            style={{"borderLeft": "6px solid #f86c6b", "padding": 10}}
            justifyContent="left"
            direction="row"
            alignItems="left"
            gap={1}
          >

            <FormControlLabel
              control={
                <Checkbox
                  data-cy="patient_checkbox"
                  checked={formValues.patient_agreement}
                  onChange={(event)=>handleValueChange("patient_agreement", event.target.checked)} />}
              label={ t("patient.agreement")} />

          </Stack>
        </Grid>
        <AuthenticatedTemplate>
          <canvas hidden={true} id="myCanvas" width="200" height="100"></canvas>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="patient_search"
              freeSolo
              autoHighlight={true}
              options={patientSearchResults || []}
              onInputChange={(event, newInputValue) => {
                if (event?.type === "change") {
                  delay(() => dispatch(fetchPatientFreeTextSearch(newInputValue, "false")), 1000);
                }
              }}
              onChange={(event, value, reason, details:any) => {
                if (details && details.option) {
                  dispatch(onSelectSearchedPatient(details.option));
                }
              }}
              getOptionLabel={(option:any) => {
                let labelForOption = option.firstname + " " + option.name;

                if (option.insurance_no) {
                  labelForOption = labelForOption + ", " +
                  "AHV:" + option.insurance_no;
                }
                if (option.insurance_company) {
                  labelForOption = labelForOption +
                  " " + option.insurance_company;
                }

                if (option.street || option.city || option.zip ) {
                  labelForOption = labelForOption + ", " +
                  t("patient.label.addresse") + ": " +
                  option.street + ", " +
                  option.city + ", " +
                  option.country + "-" + option.zip;
                }
                if (option.email_addr ) {
                  labelForOption = labelForOption + ", " +
                  t("patient.email") + ": " +
                  option.email_addr;
                }
                return labelForOption;
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("newrequest.label.searchpatient")}
                  variant="outlined"
                  multiline
                  data-cy="patient_search"
                  InputProps={{
                    ...params.InputProps,
                    readOnly: loading.patientSearch,
                    endAdornment: (
                      <React.Fragment>
                        {(loading.patientSearch) ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    startAdornment: ( <InputAdornment position="start"> <SearchIcon />
                    </InputAdornment> ),

                  }}
                />}
            />
          </Grid>
        </AuthenticatedTemplate>
        <Grid item xs={12}>
          <TextField
            fullWidth
            data-cy="patient_firstname"
            error={Boolean(validationErrors.patient_surename)}
            helperText={validationErrors.patient_surename||""}
            label={t( "doctor.details.surname")}
            value={formValues.patient_surename}
            onChange={(e) => handleValueChange("patient_surename", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            data-cy="patient_lastname"
            error={Boolean(validationErrors.patient_lastname)}
            helperText={validationErrors.patient_lastname}
            value={formValues.patient_lastname||""}
            label={t("patient.label.lastname")}
            onChange={(e) => handleValueChange("patient_lastname", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="patient_dob">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
            <DatePicker
              label={t( "patient.label.dob")}
              openTo="year"
              maxDate={datepickerLimitations.patient.patient_date_of_birth.maxDate}
              mask={calendarMask(currentLang)}
              value={formValues.patient_date_of_birth||""}
              onChange={(newValue) => handleValueChange("patient_date_of_birth", correctDateFormat(newValue))}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            select
            id="select_patient_gender"
            inputProps={{id: "select_patient_gender_input"}}
            label={t("patient.label.gender")}
            fullWidth
            data-cy="select_gender"
            value={formValues.patient_gender}
            onChange={(event) => handleValueChange("patient_gender", event.target.value)}
          >
            {[t("patient.gender.male"),
              t("patient.gender.female"),
              t("patient.gender.other")].map((option, index) => (
              <MenuItem key={option} value={index} data-cy={`patient_gender_${index}`}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth data-cy="patient_phone">
            <InputLabel htmlFor="patient_phone_no">{t("patient.label.phone.no.optional")}</InputLabel>
            <OutlinedInput
              label={t("patient.label.phone.no.optional")}
              value={formValues.patient_phone_no||""}
              onChange={(e) => handleValueChange("patient_phone_no", e.target.value)}
              id="patient_phone_no"
              inputComponent={PhoneNoComponent as any}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth data-cy="patient_email">
            <InputLabel htmlFor="patient_email_addr">{t("patient.label.email.optional")}</InputLabel>
            <OutlinedInput
              label={t("patient.label.email.optional")}
              value={formValues.patient_email_addr||""}
              error={!validateEmail(formValues.patient_email_addr)}
              onChange={(e) => handleValueChange("patient_email_addr", e.target.value)}
              id="patient_email_addr"
              inputComponent={EmailComponent as any}
            />
          </FormControl>
        </Grid>

      </Grid>

      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{t("wizard.insurance.data")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            data-cy="patient_insurance_no"
          >
            <InputLabel htmlFor="patient_insurance_no">{t("patient.label.insurance.no")}</InputLabel>
            <OutlinedInput
              label={t("patient.label.insurance.no")}
              value={formValues.patient_insurance_no||""}
              onChange={(e) => handleValueChange("patient_insurance_no", e.target.value)}
              id="patient_insurance_no"
              inputComponent={InsuranceNoComponent as any}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {Array.isArray( insuranceCompanyList ) &&
          <Autocomplete<IInsuranceCompany>
            data-cy="patient_insurancecompany"
            options={insuranceCompanyList}
            getOptionLabel={(option:any)=>`${option.DESCR1}${option.DESCR2?" "+option.DESCR2:""}`}
            isOptionEqualToValue={(option:IInsuranceCompany, value:IInsuranceCompany)=>option?.DESCR1===value?.DESCR1||value?.DESCR1===""}
            fullWidth
            autoComplete
            value={formValues.patient_insurance||""}
            autoHighlight
            autoSelect
            onChange={(event, value, reason, option) => {
              if (reason==="clear") {
                dispatch(clearSpecificPatientState( {field: "patient_insurance"}));
              } else {
                handleValueChange("patient_insurance", value);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label={t("patient.label.insurance.company")} />}
          />
          }
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{t("wizard.address.data")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formValues.patient_street||""}
            label={t("doctor.details.street")}
            onChange={(e) => handleValueChange("patient_street", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="patientZip"
            value={formValues.patient_postalcode||""}
            label={t("patient.label.zip")}
            onChange={(e) => handleValueChange("patient_postalcode", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={formValues.patient_city||""}
            label={t("doctor.details.city")}
            onChange={(e) => handleValueChange("patient_city", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete<ICountry>
            options={countries.filter((e)=>e.lang===i18n.language.toUpperCase())}
            renderOption={(props, option) => (
              <Box component="li" sx={{"& > img": {mr: 2, flexShrink: 0}}} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  // TODO_AnGh: Does Germany need to be D and not DE?
                  src={`https://flagcdn.com/w20/${option.code==="D"?"de":option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code==="D"?"de":option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.code} {option.label}
              </Box>
            )}
            value={formValues.patient_country || "CH"}
            getOptionLabel={(option:any)=>option.label || "Schweiz"}
            isOptionEqualToValue={(option:ICountry, value:ICountry)=>option.code===value.code||value.code===""}
            fullWidth
            autoComplete
            autoHighlight
            autoSelect
            onChange={(event, value, reason, option) => {
              reason==="selectOption"&&console.log(value);
              if (reason==="clear") {
                dispatch(clearSpecificPatientState({field: "patient_country"}));
              } else {
                handleValueChange("patient_country", value);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label={t("patient.label.country")} />}
          />
        </Grid>

      </Grid>
    </Grid>
  </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);

