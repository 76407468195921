import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FormGroup, Grid, FormLabel, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeTreatmentValue, selectTreatmentValues} from "./treatmentPlanSlice";
import {selectMedicineValues} from "../medicine/medicineSlice";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {onChangeKVVLegalState} from "../medicine/medicineSlice";


import {
  Box,
  Stack,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

export const TreatmentPlanForm = (props) => {
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  const {t}=useTranslation();
  const isVisibleValues = UseAppSelector(isFormVisible);
  const treatmentPlanValues= UseAppSelector(selectTreatmentValues);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const dispatch = UseAppDispatch();

  const isVisible=useIsInViewport(props.refProps);
  useEffect(()=>{
    isVisibleValues.treatment!==isVisible&&dispatch(onViewChange({field: "treatment", value: isVisible}));
  }, [isVisible]);


  const handleValueChange = (field:string, value:any)=>{
    dispatch(onChangeTreatmentValue({field, value}));
  };


  const [showWarning, setShowWarning] = useState(false);

  useEffect(()=>{
    const checkboxischecked = treatmentPlanValues.specialist_info || treatmentPlanValues.limitatio_approval;
    const isCustomRequestType =
    medicineValues.request_type === "-" ||
    medicineValues.request_type === "n/a" ||
    medicineValues.request_type === "71c KVV" ||
    medicineValues.request_type === "";
    setShowWarning(isCustomRequestType && checkboxischecked);
    console.debug(
        `[WARNING CAUSE OF CUSTOM MED IN LABEL] , 
      requestType: ${medicineValues.request_type}, pharmacode: ${medicineValues.pharmacode}`,
    );
  }, [treatmentPlanValues, medicineValues]);

  useEffect(()=>{
    if (medicineValues.pharmacode === "") {
      console.debug(
          `[WARNING CAUSE OF CUSTOM MED IN LABEL] , 
       pharmacode is empty`,
      );
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, [medicineValues.pharmacode]);

  return (<Grid ref={props.refProps} container direction="column" spacing={3} id="treatment">
    <Grid item>
      <Typography variant="h5" >
        {t(props.label)}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <FormGroup>
        <FormLabel>{t("wizard.legal.support.text")}</FormLabel>
        <FormControlLabel
          data-cy="specialist_info"
          control={<Checkbox
            checked={treatmentPlanValues.specialist_info}
            onChange={(event)=>handleValueChange("specialist_info", event.target.checked)} />}
          label={t("wizard.specialist.details.checkbox")} />
        <FormControlLabel
          data-cy="limitatio_approval"
          control={<Checkbox
            checked={treatmentPlanValues.limitatio_approval}
            onChange={(event)=>handleValueChange("limitatio_approval", event.target.checked)} />}
          label={t("wizard.limitatio.checkbox")} />
      </FormGroup>
    </Grid>
    <Grid item xs={12} data-cy="request_type">
      <Typography >{`${t("indication.details.type.credit")}`}
        <span style={{color: "#63c2de", textDecoration: "underline"}}>
          {(() => {
            switch (medicineValues.request_type) {
              case "Inlabel":
                return t("request_type_inlabel"); // Return the translated text
              default:
                return medicineValues.request_type;
            }
          })()}
        </span></Typography>
    </Grid>

    <Grid hidden={ !( showWarning)} item xs={12}>
      <Grid item xs={12} >
        <Stack
          direction="row"
          alignItems="left"
          gap={1}
        >
          <WarningIcon color={"primary"}></WarningIcon>
          <Typography>{t("treatmentplan.warning.notfound")}</Typography>
        </Stack>
      </Grid>
      <Box sx={{m: 3}}/>
    </Grid>

  </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanForm);
